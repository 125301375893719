import { extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    colors: {
        brand: {
            50: "#D7EABE",
            100: "#CDE5AD",
            500: "#9CCC5D",
            600: "#8CB753",
            700: "#7CA34A",
            800: "#6D8E41",
            900: "#5D7A37"
        },
        brandBlack: {
            100: "#FFFFFF",
            300: "#F7F7F7",
            500: "#EBEBEB",
            700: "#717171",
            900: "#222222"
        }
    }
};

const customTheme = extendTheme(config);

export default customTheme;
