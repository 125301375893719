import {
    Container,
    Heading,
    SimpleGrid,
} from '@chakra-ui/react';
import ValueCard from './valueCard';

function Values() {
    return (
        <Container py={10} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brand.500'>
            <Heading color={'white'}>Our Values</Heading>
            <SimpleGrid w='100%' py={10} columns={{ base: 2, md: 3 }} columnGap={10} rowGap={10}>
                <ValueCard
                    name="Excellence"
                    description="Committed to excellence in fitness programs, state-of-the-art facilities, and expertly crafted workouts for exceptional experiences."
                />
                <ValueCard
                    name="Inclusivity"
                    description="Welcoming all with diverse classes & virtual training. Personalized to cater to individual needs and achieve unique goals."
                />
                <ValueCard
                    name="Innovation"
                    description="Embracing tech & trends to enhance members' fitness journeys. Stay ahead with cutting-edge approaches."
                />
                <ValueCard
                    name="Community"
                    description="A supportive, inspiring environment where members flourish, united in their pursuit of fitness aspirations."
                />
                <ValueCard
                    name="Accountability"
                    description="Guiding members with consistency and responsibility to ensure success. Results-driven, positive atmosphere."
                />
                <ValueCard
                    name="Learning"
                    description="Stay updated in fitness and wellness to deliver fresh, effective workouts. Dedicated to improvement."
                />
            </SimpleGrid>
        </Container>
    );
}

export default Values;
