import { Box, Button, Container, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';

function Hero() {
    return (
        <Container width={"100%"} maxW={"100%"} py={{ base: 8, md: 20 }} px={{ base: 18, md: 28 }}>
            <Stack
                align={'center'}
                spacing={8}
                direction={{ base: 'column', md: 'row' }}
            >
                <Stack flex={1} spacing={{ base: 4, md: 6 }}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '3xl', lg: '4xl' }}
                    >
                        <Text
                            as={'span'}
                            color={'brand.500'}
                        >
                            Welcome to Vivafit
                        </Text>
                        <br />
                        <Text as={'span'}>
                            Your Path to a Healthier and Fitter Lifestyle!
                        </Text>
                    </Heading>
                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        direction={{ base: 'column', sm: 'row' }}
                    >
                        <Button
                            size={'lg'}
                            fontWeight={'normal'}
                            px={6}
                            colorScheme={'brand'}
                            onClick={() => window.location.replace("/")}
                        >
                            Membership
                        </Button>
                        <Button
                            size={'lg'}
                            fontWeight={'normal'}
                            px={6}
                            onClick={() => window.location.replace("/")}
                        >
                            Trial Class
                        </Button>
                    </Stack>
                </Stack>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                >
                    <Box
                        height={'300px'}
                        rounded={'2xl'}
                        boxShadow={'2xl'}
                        width={'full'}
                        overflow={'hidden'}
                    >
                        <Image
                            alt={'Hero Image'}
                            fit={'cover'}
                            align={'center'}
                            w={'100%'}
                            h={'100%'}
                            src={
                                'https://images.unsplash.com/photo-1575052814086-f385e2e2ad1b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80'
                            }
                        />
                    </Box>
                </Flex>
            </Stack>
        </Container>
    );
}

export default Hero;
