import {Center, Container, Heading, Text} from '@chakra-ui/react';

function About() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>About Vivafit</Heading>
            <Center mt={5} display={"flex"} flexDir={"column"}>
                <Text>At Vivafit, our mission is to empower individuals to lead healthier, happier lives through innovative and tech-enabled fitness solutions. We are dedicated to inspiring positive transformations by providing accessible and enjoyable fitness experiences for people of all ages and fitness levels.</Text>
                <Text mt={2}>Our success is driven by our exceptional team of experienced fitness trainers and instructors. Each member of our team is not only a certified expert in their field but also deeply passionate about helping others transform their lives through fitness.</Text>
                <Text mt={2}>The dedication and expertise of our trainers form the foundation of Vivafit's commitment to excellence in fitness. Together, we strive to create a fitness journey that empowers our members to thrive physically, mentally, and emotionally.</Text>
                <Text mt={2}>Join us at Vivafit and embark on a transformative fitness experience that will elevate your well-being and redefine your relationship with health and fitness.</Text>
            </Center>
        </Container>
    );
}

export default About;
