import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import ServiceCard from "./serviceCard";
import {FaCheckCircle, FaCode, FaGlobe, FaMobileAlt} from "react-icons/fa";

function Services() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>Classes at Vivafit</Heading>
            <SimpleGrid w='100%' pt={10} columns={{ base: 1, sm: 1, lg: 2 }} columnGap={10} rowGap={10}>
                <ServiceCard
                    name="Yoga"
                    imageSource="https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1820&q=80"
                />
                <ServiceCard
                    name="Pilates"
                    imageSource="https://images.unsplash.com/photo-1518611012118-696072aa579a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
                />
                <ServiceCard
                    name="HIIT"
                    imageSource="https://images.unsplash.com/photo-1599058917212-d750089bc07e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1738&q=80"
                />
                <ServiceCard
                    name="Zumba"
                    imageSource="https://images.unsplash.com/photo-1524594152303-9fd13543fe6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
                />
            </SimpleGrid>
        </Container>
    );
}

export default Services;
