import {Center, Card, CardBody, Flex, Heading, Image, Text} from '@chakra-ui/react';

function ValueCard({ imageSource, name, description, icon }) {
    return (
        <Card minHeight='40' p='4' borderRadius='12' boxShadow={'none'}>
            <CardBody w='100%'>
                <Flex flexDirection='column' w='100%'>
                    {imageSource ?
                        <Image
                            src={imageSource}
                            alt={name}
                            width={'40%'}
                            margin={'auto'}
                            fallbackSrc={'/images/icons/app.png'}
                        />
                        : icon ?
                            <Center
                                fontSize={'35px'}
                            >
                                {icon}
                            </Center>
                            :
                            <></>
                    }
                    <Heading textAlign='center' mt='6' fontSize="lg">
                        {name}
                    </Heading>
                    <Text pb='.5rem' mt='2' lineHeight='1.6' textAlign='center'>
                        {description}
                    </Text>
                </Flex>
            </CardBody>
        </Card>
    );
}

export default ValueCard;
