import Header from '../partials/common/header/header';
import Hero from '../partials/home/hero';
import Footer from '../partials/common/footer/footer';
import About from "../partials/home/about";
import Values from "../partials/home/values";
import Services from "../partials/home/services";
import Testimonials from "../partials/home/testimonials";

function Home() {
    return (
        <>
            <Header />
            <Hero />
            <About />
            <Values />
            <Services />
            <Testimonials />
            <Footer />
        </>
    );
}

export default Home;
