import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import TestimonialCard from './testimonialCard';

function Testimonials() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.900'>
            <Heading color={'white'}>Testimonials</Heading>
            <SimpleGrid w='100%' pt={10} columns={1} columnGap={20} rowGap={10}>
                <TestimonialCard
                    name="Rajesh Khanna"
                    description="Vivafit has been a game-changer for me! The yoga classes helped me find inner peace and balance, while the HIIT sessions pushed me to new levels of fitness. Thanks to Vivafit, I'm now healthier, happier, and more confident!"
                />
                <TestimonialCard
                    name="Ananya Gupta"
                    description="Joining Vivafit was the best decision I made! The inclusive and supportive environment made me feel at ease, and the Zumba classes were a blast. I lost weight, gained friends, and transformed my lifestyle for the better!"
                />
                <TestimonialCard
                    name="Amit Patel"
                    description="Pilates at Vivafit transformed my posture and core strength. The trainers were fantastic, guiding me with personalized workouts. I can't believe how much stronger I've become, both physically and mentally."
                />
                <TestimonialCard
                    name="Neha Sharma"
                    description="As a busy professional, virtual training with Vivafit was a game-changer. I could work out from home, and the accountability from the trainers helped me stay on track. My fitness levels have soared, and I feel fantastic!"
                />
                <TestimonialCard
                    name="Vikram Singh"
                    description="Being a part of Vivafit's community has been incredible. The camaraderie and encouragement from fellow members kept me motivated. I smashed my fitness goals and feel more energetic than ever before!"
                />
            </SimpleGrid>
        </Container>
    );
}

export default Testimonials;
